<template>
    <fieldset class="fieldset">
        <legend>Free Spaces</legend>
        <b-row>
            <b-col cols="5" class="pr-3">
                <b-button size="sm" pill block class="mb-2" @click="preset(0)">4 Corners</b-button>
                <b-button size="sm" pill block class="mb-2" :disabled="!(dimension % 2)" @click="preset(1)">Center</b-button>
                <b-button size="sm" pill block class="mb-2" @click="preset(2)">None</b-button>
            </b-col>
            <b-col cols="7">
                <div class="bingo-free-spaces">
                    <div class="bingo-card" :class="['columns-' + dimension]" :style="{ borderColor: style.color }">
                        <div
                            v-for="index in squared"
                            :key="'bingo-square' + dimension + '-' + index"
                            class="bingo-cell"
                            :style="{ borderColor: style.color }"
                            @click="toggleFreeSpace(index)"
                        >
                            <span
                                v-show="isFreeSpace(index)"
                                class="free-space"
                                :style="{
                                    color: style.color,
                                    borderColor: style.color,
                                }"
                            >
                                <b-iconstack>
                                    <b-icon stacked icon="star-fill" scale="0.75"></b-icon>
                                    <b-icon stacked icon="circle"></b-icon>
                                </b-iconstack>
                            </span>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
    </fieldset>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
    name: 'BingoFreeSpaceForm',
    computed: {
        ...mapGetters({
            bingo: 'document/bingo',
            style: 'document/bingoStyle',
        }),
        squared() {
            return this.dimension * this.dimension
        },
        dimension() {
            return Number(this.bingo.dimension)
        },
    },
    methods: {
        preset(index) {
            let freeSpaces = []

            let d = this.dimension
            if (index === 0) {
                // four corners
                freeSpaces.push(1) // push the first
                freeSpaces.push(d) // push the second
                freeSpaces.push(d * (d - 1) + 1) // push the Third
                freeSpaces.push(d * d) // push the last one.
            }

            if (index === 1) {
                //center
                freeSpaces.push(Math.ceil(this.squared / 2))
            }

            if (index === 2) {
            } //do nothing the reset above will wipe them out

            this.$store.dispatch('document/setBingo', {
                free_spaces: freeSpaces,
            })

            this.$emit('change')

            this.$store.dispatch('document/storeDocumentState')
        },
        isFreeSpace(index) {
            let inFreeSpaces = this.bingo.free_spaces.indexOf(index)
            if (inFreeSpaces >= 0) {
                return true
            } else {
                return false
            }
        },
        toggleFreeSpace(index) {
            //clone the free_spaces array
            let freeSpaces = JSON.parse(JSON.stringify(this.bingo.free_spaces))

            let inFreeSpaces = freeSpaces.indexOf(index)

            if (inFreeSpaces >= 0) {
                freeSpaces.splice(inFreeSpaces, 1)
            } else {
                freeSpaces.push(index)
            }
            this.$store.dispatch('document/setBingo', {
                free_spaces: freeSpaces,
            })

            this.$emit('change')

            this.$store.dispatch('document/storeDocumentState')
        },
    },
})
</script>

<style lang="scss">
@import 'Scss/base.scss';
@import 'Scss/runtime.scss';

.bingo-free-spaces {
    .bingo-card {
        border: 1px solid;
        display: grid;
        margin: auto;

        .bingo-cell {
            border: 1px solid;
            background-color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 100%;
            cursor: pointer;
            position: relative;

            .free-space {
                color: $primary;
                position: absolute;
                top: 0px;
                bottom: 0px;
                left: 0px;
                right: 0px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        &.columns-3 {
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(3, 1fr);

            .free-space {
                font-size: 44px;
            }
        }
        &.columns-4 {
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: repeat(4, 1fr);
            .free-space {
                font-size: 34px;
            }
        }
        &.columns-5 {
            grid-template-columns: repeat(5, 1fr);
            grid-template-rows: repeat(5, 1fr);
            .free-space {
                font-size: 26px;
            }
        }
        &.columns-6 {
            grid-template-columns: repeat(6, 1fr);
            grid-template-rows: repeat(6, 1fr);
            .free-space {
                font-size: 22px;
            }
        }
        &.columns-7 {
            grid-template-columns: repeat(7, 1fr);
            grid-template-rows: repeat(7, 1fr);
            .free-space {
                font-size: 20px;
            }
        }
    }
}
</style>
