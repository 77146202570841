<template>
    <div class="mb-0 f-11 text-white font-italic text-right">
        <span v-if="showSaveErrorMessage" class="pl-1">Error saving document. Please check your connection.</span>
        <div v-else>
            {{ savedAt }}
            <span v-if="document.is_loading" class="pl-1">Saving Document...</span>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import moment from 'moment-timezone'
import { mapGetters, mapState } from 'vuex'

export default defineComponent({
    name: 'LastSaved',
    computed: {
        ...mapGetters({
            isLoggedIn: 'user/isLoggedIn',
            isDcoumentPublishable: 'document/isPublishable',
            isBingo: 'document/isBingo',
            isFlashcard: 'document/isFlashcard',
            isWorksheet: 'document/isWorksheet',
            persistAction: 'document/persistAction',
            lastSavedAt: 'document/lastSavedAt',
            showSaveErrorMessage: 'document/saveError',
        }),
        ...mapState(['user', 'document', 'subscription']),
        savedAt() {
            if (!this.document.id) {
                return this.isLoggedIn ? 'Document not saved' : 'Sign in to save'
            }

            let updated_at = this.lastSavedAt

            if (isNaN(this.lastSavedAt)) {
                updated_at = `${this.lastSavedAt}Z`
            } else {
                updated_at = `${moment.unix(this.lastSavedAt).format('YYYY-MM-DD hh:mm')}`
            }

            return `Last saved at ${moment(updated_at).format('h:mm a')}`
        },
    },

    methods: {
        saveDocument() {
            if (this.isLoggedIn) {
                this.editing = false

                this.$store.dispatch(this.persistAction)
            } else {
                this.$modals.open('logIn')
                this.$modals.logIn.active_tab = 'sign_up'
            }
        },
    },
})
</script>
