<template>
    <div class="pt-2 mb-2">
        <label>{{ getLabel }}</label>
        <b-input-group v-for="(item, index) in fakeAnswers" :key="index" class="mb-2">
            <b-form-input
                :ref="'fake_answer-' + index"
                type="text"
                class="flex-grow-1"
                :value="fakeAnswers[index]"
                @input="setValue(index, $event)"
                @change="$store.dispatch('document/storeDocumentState')"
            />

            <template #append>
                <b-button variant="danger" href="#" @click="$store.dispatch('document/removeFakeAnswer', index)">
                    <b-icon icon="x"></b-icon>
                </b-button>
            </template>
        </b-input-group>

        <b-form-input type="text" :placeholder="getPlaceholder" @keydown="addNewItem($event)" />
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState, mapGetters } from 'vuex'

export default defineComponent({
    name: 'FakeAnswers',
    data() {
        return {
            currentValue: '',
        }
    },
    computed: {
        ...mapState(['document']),
        ...mapGetters({
            worksheet: 'document/worksheet',
            fakeAnswers: 'document/fakeAnswers',
        }),
        getLabel() {
            if (this.worksheet.type === 'matching') {
                return 'Fake answers'
            } else if (this.worksheet.type === 'fill_in_the_blank' || this.worksheet.type === 'word_scramble') {
                return 'Extra words'
            }
            return ''
        },
        getPlaceholder() {
            if (this.worksheet.type === 'matching') {
                return 'Fake matching answer'
            } else if (this.worksheet.type === 'fill_in_the_blank' || this.worksheet.type === 'word_scramble') {
                return 'Extra word'
            }
            return ''
        },
    },
    methods: {
        setValue(index, value) {
            this.$store.dispatch('document/setFakeAnswer', {
                index: index,
                value: value,
            })
            this.$store.dispatch('document/storeDocumentState')
        },
        addNewItem(evt) {
            if (typeof evt === 'undefined' || evt.key !== 'Tab') {
                //get the fake answers length
                let index = this.fakeAnswers.length

                //push a fake answer
                this.$store.dispatch('document/pushFakeAnswer', '')

                this.$nextTick(() => {
                    this.$refs['fake_answer-' + index][0].$el.focus()
                })
            }
        },
    },
})
</script>
