export default {
    section_header: 'instruction',

    divider: 'section_header',

    page_break: 'section_header',

    instruction: 'open_response',

    word_bank: 'fill_in_the_blank',

    multiple_choice: 'multiple_choice',

    word_scramble: 'word_scramble',

    fill_in_the_blank: 'fill_in_the_blank',

    handwriting: 'handwriting',

    open_response: 'open_response',

    matching: 'divider',

    blank_space: 'blank_space',

    basic_text: 'basic_text',

    checklist: 'checklist',
}
