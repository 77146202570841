<template>
    <div>
        <Draggable v-model="options" v-bind="{ handle: '.multi-option-handle', animation: 250 }" :disabled="disabled">
            <div v-for="(option, index) in options" :key="index" class="d-flex align-items-center mb-2 justify-between">
                <b-input-group>
                    <b-input-group-prepend>
                        <b-button
                            class="multi-option-handle"
                            size="sm"
                            variant="secondary"
                            tabindex="-1"
                            :disabled="disabled"
                        >
                            <b-icon icon="grip-horizontal"></b-icon>
                        </b-button>
                    </b-input-group-prepend>

                    <b-form-input
                        :id="parentIndex + '-choice-item-' + index"
                        :ref="parentIndex + '-choice-item-' + index"
                        :placeholder="isListItems ? `Item ${parseInt(index) + 1}` : 'Option'"
                        size="sm"
                        :value="option.answer"
                        :class="{ 'restore-border-radius': hasSingleOption }"
                        :disabled="disabled"
                        @input="updateOption(index, $event)"
                    ></b-form-input>

                    <b-input-group-append v-if="!hasSingleOption" style="z-index: 0">
                        <b-button
                            size="sm"
                            class="delete-button d-flex align-items-center"
                            tabindex="-1"
                            :disabled="disabled"
                            @click="removeOption(index)"
                        >
                            <svg
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                class="text-danger"
                                height="20"
                                width="20"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                />
                            </svg>
                        </b-button>
                    </b-input-group-append>
                    <span class="d-flex ml-2">
                        <b-form-checkbox
                            v-if="includeAnswerCheck"
                            :id="`correct-checkbox-${index}`"
                            :key="`option-${index}`"
                            v-model="option.correct"
                            name="options"
                            class="f-14 text-muted ml-1 mr-n2"
                            tabindex="-1"
                            switch
                            :value-field="option.correct"
                            :disabled="disabled"
                            @change="updateCorrectOption"
                        ></b-form-checkbox>
                    </span>
                </b-input-group>
            </div>
        </Draggable>
        <div class="d-flex my-2">
            <b-input
                ref="add-new-multi-option"
                v-model="newMultiOption"
                type="text"
                :style="{ width: optionWidth, marginLeft: '2.1rem' }"
                size="sm"
                list="add-new-multi-option"
                :placeholder="isListItems ? 'Add New Item' : 'Add New Option'"
                :disabled="disabled"
            />
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import Draggable from 'vuedraggable'

export default defineComponent({
    name: 'MultiOptions',
    components: {
        Draggable,
    },
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        focusIndex: {
            type: Number,
            default: -1,
        },
        hasAnswer: {
            type: Boolean,
            default: true,
        },
        isListItems: {
            type: Boolean,
            default: false,
        },
        parentIndex: {
            type: Number,
            default: -1,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            newMultiOption: '',
            options: [],
            optionWidth: '100%',
            includeAnswerCheck: this.hasAnswer ?? true,
        }
    },
    computed: {
        hasSingleOption: function () {
            return this.options.length === 1
        },
    },
    watch: {
        options: function () {
            this.$emit('input', this.options)
        },
        newMultiOption(val) {
            if (val.trim()) {
                this.addOption(val.trim())
            }
        },
        focusIndex: {
            immediate: true,
            handler(val) {
                if (val !== null && document.getElementById(this.parentIndex + `-choice-item-${val}`)) {
                    this.focusItem(val)
                }

                if (this.$refs[this.parentIndex + '-choice-item-0']) {
                    const firstInput = this.$refs[this.parentIndex + '-choice-item-0'][0].$el
                    if (firstInput) {
                        this.optionWidth = firstInput.clientWidth + 'px'
                    }
                }
            },
        },
    },
    mounted() {
        this.options = this.value

        this.$nextTick(() => {
            if (this.$refs[this.parentIndex + '-choice-item-0']) {
                const firstInput = this.$refs[this.parentIndex + '-choice-item-0'][0].$el
                if (firstInput) {
                    this.optionWidth = firstInput.clientWidth + 'px'
                }
            }

            if (this.focusIndex !== null) {
                this.focusItem(this.focusIndex)
            }
        })
    },
    methods: {
        updateCorrectOption() {
            this.$emit('input', this.options)
        },
        updateOption(index, value) {
            this.options[index].answer = value
            this.$emit('input', this.options)
        },
        removeOption(index) {
            this.options.splice(index, 1)
            this.$emit('input', this.options)
        },
        addOption(val = '') {
            this.options.push({
                answer: val,
                correct: false,
            })

            this.$emit('input', this.options)

            this.$nextTick(() => {
                let index = this.options.length - 1
                this.$refs[this.parentIndex + '-choice-item-' + index][0].$el?.focus()
                this.newMultiOption = ''
            })
        },
        focusItem(val) {
            document.getElementById(this.parentIndex + `-choice-item-${val}`)?.focus()
            this.$emit('clearFocusIndex')
        },
    },
})
</script>

<style lang="scss" scoped>
.delete-button {
    background: #efefef;
    border: 1px solid #ced4da;
}
.restore-border-radius {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
}
</style>
