<template>
    <div class="flex flex-grow-1 flex-row">
        <div class="d-flex align-items-center justify-content-between">
            <div
                class="position-relative rounded flex-grow-1 py-0 d-flex align-items-center"
                :class="screenSizeTooSmall ? 'pr-2' : 'px-2'"
            >
                <label id="folder-label" class="folder-label" for="file-name-input" aria-label="Filename">
                    <IconFile />
                </label>
                <b-input
                    id="file-name-input"
                    ref="save"
                    v-model="documentFilename"
                    class="save-document-input"
                    type="text"
                    size="sm"
                    aria-describedby="folder-label"
                    placeholder="[Worksheet Filename]"
                    :maxlength="maxLength"
                ></b-input>
            </div>
            <div>
                <button v-if="!editing" class="btn btn-success btn-sm" :disabled="filenameEmpty" @click="saveDocument">
                    {{ document.id ? 'Update' : 'Save' }}
                </button>

                <button v-else class="btn btn-success btn-sm" @click="saveDocument">Saving...</button>
            </div>
        </div>
        <LastSaved v-if="screenSizeTooSmall" />
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters, mapState } from 'vuex'
import { filenameFromTitle } from '../common/helpers'
import DocumentHelper from '../mixins/DocumentHelper'
import StatsigHelper from '../mixins/StatsigHelper'
import UserEvent from '../objects/UserEvent'
import IconFile from '../stories/components/icons/IconFile.vue'
import LastSaved from './LastSaved.vue'
import debounce from 'lodash/debounce'

export default defineComponent({
    name: 'Save',
    components: { IconFile, LastSaved },
    mixins: [DocumentHelper, StatsigHelper],
    props: {
        screenSizeTooSmall: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            filename: '',
            editing: false,
        }
    },
    computed: {
        ...mapGetters({
            isLoggedIn: 'user/isLoggedIn',
            isBingo: 'document/isBingo',
            isFlashcard: 'document/isFlashcard',
            isWorksheet: 'document/isWorksheet',
            persistAction: 'document/immediatePersistAction',
            maxLength: 'document/filenameMaxLength',
            defaultDocumentTitle: 'document/defaultDocumentTitle',
        }),
        ...mapState(['user', 'document', 'subscription']),
        documentFilename: {
            get() {
                return this.filename
            },
            set(value) {
                this.filename = value.trim().substring(0, this.maxLength)

                this.$store.dispatch('document/setDocument', {
                    filename: this.filename,
                })

                this.$store.dispatch('document/setDocument', {
                    filenameEdited: true,
                })

                this.$nextTick(() => {
                    this.$store.dispatch('document/storeDocumentState')
                })
            },
        },
        nameFromTitle() {
            const title = Boolean(this.document.title) ? this.document.title.replace(/(<([^>]+)>)/gi, '') : ''
            const titleContent = this.document.filenameEdited
                ? this.document.filename
                : title !== this.defaultDocumentTitle
                  ? title
                  : ``
            return filenameFromTitle(titleContent, this.document.entity_type).substring(0, this.maxLength)
        },
        filenameEmpty() {
            return !this.documentFilename || (this.documentFilename && this.documentFilename.trim() === '')
        },
        fileAndTitleValue() {
            return `${this.document.title}|${this.document.filename}`
        },
    },
    watch: {
        fileAndTitleValue: {
            handler() {
                const title = Boolean(this.document.title) ? this.document.title.replace(/(<([^>]+)>)/gi, '') : ''
                if (this.documentFilename?.trim() !== '' || title?.trim() === this.document.filename?.trim())
                    this.filename = this.nameFromTitle
            },
            immediate: true,
        },
    },
    mounted() {
        const debouncedSave = debounce(async () => {
            if (!this.isLoggedIn) return

            if (this.documentFilename?.trim() === '') this.filename = this.nameFromTitle

            this.editing = true
            await this.$store.dispatch(this.persistAction, true).finally(() => {
                this.editing = false
            })
        }, 2000)

        this.$refs.save.$el.addEventListener('keyup', debouncedSave)
    },
    async created() {
        if (window.copying) {
            this.setInitialDocumentValues()
        }

        if (this.document.filename) {
            this.$store.dispatch('document/setDocument', {
                filenameEdited: true,
            })

            this.filename = this.document.filename
        } else {
            this.filename = ''
        }

        await this.$store.dispatch('document/setDocument', {
            filename: this.filename,
        })
    },
    methods: {
        async saveDocument() {
            if (this.isLoggedIn) {
                this.editing = true

                await this.$store.dispatch(this.persistAction, true).finally(() => {
                    this.editing = false
                })
            } else {
                let message = ''

                if (this.isWorksheet) {
                    message = 'worksheet_filename_save_button'
                }

                if (this.isFlashcard) {
                    message = 'flashcard_filename_save_button'
                }

                if (this.isBingo) {
                    message = 'bingo_filename_save_button'
                }

                await this.$eventApi.create_event(UserEvent.SIGN_UP_OPEN, message)

                this.$modals.open('logIn')
                this.$modals.logIn.active_tab = 'sign_up'
                await this.logSeenEmailOverlay()
            }
        },
    },
})
</script>

<style lang="scss" scoped>
.save-document-input {
    background-color: darken(#fff, 80%);
    color: darken(#fff, 20%);
    font-weight: 700;
    padding-left: 40px;
    border: 2px solid darken(#fff, 60%);
}
.folder-label {
    position: absolute;
    top: 4px;
    left: 16px;
}
</style>
