<template>
    <div>
        <fieldset class="fieldset my-2">
            <legend>LINE OPTIONS</legend>
        </fieldset>
        <div class="d-flex flex-row justify-content-between align-items-center">
            <div class="d-flex flex-column w-100">
                <span class="f-11 font-weight-bold text-muted text-nowrap mb-0">Position</span>
                <div class="d-flex flex-row w-100">
                    <b-form-radio v-model="showLineBefore" value="before" @change="updateData">
                        <div class="f-12" style="height: 1.5rem; line-height: 1.5rem">
                            <span style="margin-left: -4px">Before</span>
                        </div>
                    </b-form-radio>
                    <b-form-radio v-model="showLineBefore" class="ml-2" value="after" @change="updateData">
                        <div class="f-12" style="height: 1.5rem; line-height: 1.5rem">
                            <span style="margin-left: -4px">After</span>
                        </div>
                    </b-form-radio>
                </div>
            </div>
            <div class="d-flex flex-column w-100">
                <span class="f-11 font-weight-bold text-muted text-nowrap mb-0">Alignment</span>
                <div class="d-flex flex-row w-100">
                    <b-form-checkbox
                        v-model="alignHorizontally"
                        name="align-line-horizontally"
                        size="sm"
                        switch
                        :disabled="showLineBefore === 'before'"
                        @change="updateData"
                    >
                        <span style="margin-left: -4px">Horizontal</span>
                    </b-form-checkbox>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { MatchingDataSettings } from '../../types/matching'

interface Data {
    showLineBefore: string
    alignHorizontally: boolean
}

export default defineComponent({
    name: 'MatchingLine',
    props: {
        item: {
            type: Object as PropType<MatchingDataSettings>,
            required: true,
        },
    },
    data(): Data {
        return {
            showLineBefore: this.item.blank_position,
            alignHorizontally: this.item.align_line_horizontally,
        }
    },
    watch: {
        showLineBefore(val: string) {
            if (val === 'before') this.alignHorizontally = true
        },
    },
    methods: {
        updateData() {
            this.$emit('updateData', {
                blank_position: this.showLineBefore,
                align_line_horizontally: this.alignHorizontally,
            })
        },
    },
})
</script>
