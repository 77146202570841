<template>
    <b-form-group>
        <b-form-radio
            v-model="alignment"
            value="start"
            button
            button-variant="outline-primary"
            :size="size"
            class="mr-1 alignment"
            @input="handleInput"
        >
            <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M9.16667 13.3333V10.8333H17.5V9.16666H9.16667V6.66666L5.83333 9.99999L9.16667 13.3333ZM4.16667 3.33333H2.5V16.6667H4.16667V3.33333Z"
                    fill="currentColor"
                />
            </svg>
        </b-form-radio>
        <b-form-radio
            v-model="alignment"
            value="center"
            button
            button-variant="outline-primary"
            :size="size"
            class="mr-1 alignment"
            @input="handleInput"
        >
            <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M15.0416 13.3333V10.8333H18.2083V9.16666H15.0416V6.66666L11.875 9.99999L15.0416 13.3333ZM3.95829 6.66666V9.16666H0.791626L0.791626 10.8333H3.95829L3.95829 13.3333L7.12496 9.99999L3.95829 6.66666ZM8.70829 16.6667H10.2916V3.33332H8.70829V16.6667Z"
                    fill="currentColor"
                />
            </svg>
        </b-form-radio>
        <b-form-radio
            v-model="alignment"
            value="end"
            button
            button-variant="outline-primary"
            :size="size"
            class="alignment"
            @input="handleInput"
        >
            <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.8333 6.66666V9.16666H2.5V10.8333H10.8333V13.3333L14.1667 9.99999L10.8333 6.66666ZM15.8333 16.6667H17.5V3.33332L15.8333 3.33332V16.6667Z"
                    fill="currentColor"
                />
            </svg>
        </b-form-radio>
    </b-form-group>
</template>

<script>
export default {
    name: 'Alignment',
    props: {
        value: {
            type: String,
            default: undefined,
        },
        size: {
            type: String,
            default: 'sm',
        },
    },
    data() {
        return {
            alignment: this.value,
        }
    },
    methods: {
        handleInput() {
            this.$emit('input', this.alignment)
        },
    },
}
</script>
