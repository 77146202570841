<template>
    <div class="items-wrapper">
        <div ref="itemsHolder" class="items-holder">
            <Draggable v-model="documentItems" v-bind="{ handle: '.item-handle' }">
                <b-card v-for="(item, index) in documentItems" :key="index" class="mb-2 position-relative" no-body>
                    <b-card-header class="py-0 px-1 d-flex justify-content-between align-items-center position-relative">
                        <div>
                            <a
                                :id="'item-handle-' + index"
                                v-b-tooltip.hover
                                href="#"
                                title="Reorder"
                                class="item-handle mr-2 text-secondary text-lg"
                                aria-label="drag"
                                tabindex="-1"
                                @click.prevent=""
                                @keyup.up="moveItemUp(index)"
                                @keyup.down="moveItemDown(index)"
                            >
                                <b-icon icon="grip-horizontal"></b-icon>
                            </a>
                        </div>
                        <a
                            v-b-tooltip.hover
                            href="#"
                            title="Remove Item"
                            class="text-danger text-lg"
                            aria-label="Delete"
                            tabindex="-1"
                            @click.prevent="removeItem(index)"
                        >
                            <b-icon icon="x-circle-fill"></b-icon>
                        </a>
                    </b-card-header>
                    <b-card-body class="p-1">
                        <FlashcardItemForm
                            :is="inputType"
                            :ref="'flashcard-item-' + index"
                            :index="index"
                            @focus-next="handleFocusNext(index)"
                        />
                    </b-card-body>
                </b-card>
            </Draggable>
            <b-card v-if="showNewItemForm" no-body>
                <b-card-header></b-card-header>
                <b-card-body class="p-1">
                    <div class="mb-0 d-flex">
                        <VueEditor
                            :ref="'flashcard_front_word'"
                            v-model="itemTerm"
                            class="flashcard-input front hidden-header"
                            :editor-toolbar="customToolbar"
                            :editor-options="editorSettings"
                            placeholder="Front (optional)"
                            @input="handleNewAddInput"
                            @focus="focusNewFlashcard"
                        ></VueEditor>
                        <InlineImageBtn
                            btn-class="flashcard-inline-image-btn front"
                            :input-index="documentItems.length"
                            column="term"
                            @click="handleNewAddInput(`${documentItems.length}`)"
                        />
                    </div>
                    <div class="mb-0 d-flex">
                        <VueEditor
                            :ref="'flashcard_back_word'"
                            v-model="itemDefinition"
                            class="flashcard-input back hidden-header"
                            :editor-toolbar="customToolbar"
                            :editor-options="editorSettings"
                            placeholder="Back (optional)"
                            @input="handleNewAddInput"
                            @focus="focusNewFlashcard"
                        ></VueEditor>
                        <InlineImageBtn
                            :input-index="documentItems.length"
                            column="definition"
                            :btn-class="[{ 'border-top-0': hideBorder() }, 'flashcard-inline-image-btn back']"
                            @click="handleNewAddInput(`${documentItems.length}`)"
                        />
                    </div>
                </b-card-body>
            </b-card>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import Draggable from 'vuedraggable'
import FakeAnswers from './FakeAnswers.vue'
import { mapGetters, mapState } from 'vuex'
import { copyObject } from '../helpers/copyObject'
import { VueEditor } from 'vue2-editor'
import InlineImageBtn from './AddInlineImageButton.vue'
import InlineImagesMixin from '../mixins/InlineImages'
import FlashcardItemForm from '../widgets/item-forms/flashcard-item-form.vue'
import StartOverButton from './StartOverButton.vue'

export default defineComponent({
    name: 'FlashcardItemsForm',
    components: {
        StartOverButton,
        Draggable,
        FakeAnswers,
        FlashcardItemForm,
        VueEditor,
        InlineImageBtn,
    },
    mixins: [InlineImagesMixin],
    data() {
        return {
            itemSort: { field: 'term', dir: 'asc' },
            itemTerm: '',
            itemDefinition: '',
            customToolbar: [['bold', 'italic', 'underline'], [], []],
            editorSettings: {
                formats: ['bold', 'italic', 'underline', 'script'],
                modules: {
                    keyboard: {
                        bindings: {
                            enter: {
                                key: 13,
                                handler: function () {
                                    if (self === window) return true
                                    self.onNextFocus()
                                    return false
                                },
                            },
                            tab: {
                                key: 9,
                                handler: function () {
                                    if (self === window) return true
                                    self.onNextFocus()
                                    return false
                                },
                            },
                        },
                    },
                },
            },
        }
    },
    computed: {
        ...mapState(['user', 'document']),
        ...mapGetters({
            items: 'document/documentItems',
        }),
        documentItems: {
            get() {
                return this.items
            },
            set(values) {
                // for resorting
                this.$store.dispatch('document/dragSortItems', values)
            },
        },
        inputType() {
            return this.document.entity_type + '-item-form'
        },
        showNewItemForm() {
            return (
                !this.documentItems.length ||
                (this.documentItems.length > 0 &&
                    (!this.isEmpty(this.documentItems[this.documentItems.length - 1].definition) ||
                        !this.isEmpty(this.documentItems[this.documentItems.length - 1].term) ||
                        !this.isEmpty(this.documentItems[this.documentItems.length - 1].term_image) ||
                        !this.isEmpty(this.documentItems[this.documentItems.length - 1].definition_image)))
            )
        },
    },
    watch: {
        '$nav.active_panel': function (newPanel) {
            if (newPanel !== 'body') return
            //non-reactive property
            this.tabIndex = false
            this.resetItemSort()
            this.$refs['itemsHolder'].scrollTop = 0
        },
    },
    created() {
        this.tabIndex = false
        this.resetItemSort()
    },
    mounted() {
        window.addEventListener('keydown', (e) => {
            if (e.repeat || e.key !== 'Enter') return

            const isInputFocused = Array.from(document.querySelectorAll('.flashcard-input')).filter((element) => {
                return document.activeElement === element
            }).length

            if (isInputFocused) {
                this.addNewItem()
            }
        })
    },
    updated() {
        if (!this.tabIndex) return

        this.$refs['definition'][this.tabIndex].$el.focus()
        this.tabIndex = false
    },
    methods: {
        handleFocusNext(index) {
            const lastIndex = this.documentItems.length - 1
            if (lastIndex === index) {
                this.$refs['flashcard_front_word']?.quill.root.focus()
                return
            }

            this.$refs['flashcard-item-' + (index + 1)][0]?.focusFrontWord()
        },
        moveItemUp(index) {
            let items = copyObject(this.documentItems)
            let newIndex

            if (index) {
                newIndex = index - 1
            } else {
                newIndex = items.length - 1
            }

            //get the item from its current index
            let item = items.splice(index, 1)[0]
            //place it in it's new index
            items.splice(newIndex, 0, item)

            //write over the original flashcard items with our new one.
            this.documentItems = items
            this.$nextTick(() => {
                document.getElementById('item-handle-' + newIndex).focus()
            })
        },
        moveItemDown(index) {
            let items = copyObject(this.documentItems)

            let newIndex
            if (index === items.length - 1) {
                newIndex = 0
            } else {
                newIndex = index + 1
            }

            //get the item from its current index
            let item = items.splice(index, 1)[0]
            //place it in its new index
            items.splice(newIndex, 0, item)

            //write over the original flashcard items with our new one.
            this.documentItems = items

            this.$nextTick(() => {
                document.getElementById('item-handle-' + newIndex).focus()
            })
        },
        setFocus() {
            this.$refs['flashcard-item-' + (this.items.length - 1)][0]?.focusFrontWord()
        },
        sortItemList(field, direction) {
            this.$store.dispatch('document/sortItems', {
                field: field,
                direction: direction,
            })
        },
        resetItemSort() {
            this.itemSort = { field: false, dir: false }
        },
        sortItems() {
            if (this.itemSort.field) {
                if (this.itemSort.field === 'randomize') {
                    this.$store.dispatch('document/randomizeItems')
                } else {
                    this.sortItemList(this.itemSort.field, this.itemSort.dir)
                }
            }
        },
        tab(type, e, index) {
            if (index !== this.items.length - 1 && type === 'term') return

            e.preventDefault()
            this.tabIndex = index + 1
            this.addNewItem()
        },
        addNewItem(data = null) {
            this.$store.dispatch('document/pushNewItem', data)

            this.$nextTick(function () {
                this.setFocus()
            })
        },
        removeItem(index) {
            this.$store.dispatch('document/removeItem', index)
        },
        clearItems() {
            this.$store.dispatch('document/clearItems')
            this.$store.dispatch('document/clearFakeAnswers')
        },
        swapItem(index) {
            this.$store.dispatch('document/swapItem', index)
        },
        swapAllItems() {
            for (let i = 0; i < this.documentItems.length; i++) {
                this.swapItem(i)
            }
        },
        handleNewAddInput(value) {
            if (this.isEmpty(value)) return

            const term = this.itemTerm
            const definition = this.itemDefinition
            const definition_image = ''
            const term_image = ''
            const item = {
                term: term,
                definition: definition,
                definition_image: definition_image,
                term_image: term_image,
            }

            this.addNewItem(item)

            if (this.itemTerm) {
                const quill = this.$refs.flashcard_front_word?.quill
                if (quill) {
                    quill.setContents([])
                    quill.setText('')
                }
            } else if (this.itemDefinition) {
                const quill = this.$refs.flashcard_back_word?.quill
                if (quill) {
                    quill.setContents([])
                    quill.setText('')
                }
            }
            this.$store
                .dispatch('document/updateItem', {
                    index: this.documentItems.length - 1,
                    item: {
                        term,
                        definition,
                        definition_image,
                        term_image,
                    },
                })
                .then(() => {
                    document.getElementById(`flashcard-front-${this.documentItems.length - 1}`)
                })
            this.itemTerm = ''
            this.itemDefinition = ''
        },
        isEmpty(value) {
            return value === null || value?.trim()?.length === 0
        },
        focusNewFlashcard() {
            let elem = document.getElementById('workspaceContainer')
            elem.scrollIntoView({ block: 'center', behavior: 'smooth' })
        },
    },
})
</script>

<style lang="scss">
@import 'Scss/base.scss';

.custom-tools {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.items-wrapper {
    $button-size: 22px;

    .item-wrapper {
        position: relative;
        border-radius: $border-radius;
        margin-bottom: 15px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);

        .group-vertical {
            border-radius: $border-radius;
        }

        .item-wrapper-group {
            padding: 10px;
            border: 1px solid $gray-300;
        }

        .item-addon-section {
            background-color: $gray-400;

            padding: 10px;
            border: 1px solid $gray-400;
            border-top: 0;
            border-bottom-width: 0;

            &.last {
                background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.75) 0%, $gray-400 100%);
                border-bottom-right-radius: $border-radius;
                border-bottom-left-radius: $border-radius;
                border-bottom-width: 1px;
            }
        }
    }

    .items-holder {
        flex-grow: 1;

        .item-handle {
            cursor: grab;
        }
    }
    .sortable-chosen,
    .sortable-ghost {
        .item-handle {
            cursor: grabbing !important;
        }
    }
    .swap-container {
        position: relative;
        overflow: visible;
        justify-content: center;
        align-items: center;
        display: flex;
        font-size: 1.3em;
        height: 0;
    }
}
</style>
