<template>
    <div>
        <fieldset class="fieldset my-2">
            <legend>DOT OPTIONS</legend>
        </fieldset>
        <div class="d-flex flex-row justify-content-between align-items-center">
            <div class="d-flex flex-column w-100">
                <span class="f-11 font-weight-bold text-muted text-nowrap mb-0">Show Dots</span>
                <div class="d-flex flex-row w-100">
                    <b-form-checkbox v-model="showDotsLeft" name="show-dots-left" size="sm" @change="updateData">
                        <span style="margin-left: -4px">Left</span>
                    </b-form-checkbox>
                    <b-form-checkbox
                        v-model="showDotsRight"
                        class="ml-2"
                        name="show-dots-right"
                        size="sm"
                        @change="updateData"
                    >
                        <span style="margin-left: -4px">Right</span>
                    </b-form-checkbox>
                </div>
            </div>
            <div class="d-flex flex-column w-100">
                <span class="f-11 font-weight-bold text-muted text-nowrap mb-0">Align Dots</span>
                <div class="d-flex flex-row w-100">
                    <b-form-checkbox
                        v-model="alignHorizontally"
                        name="align-dots-horizontally"
                        size="sm"
                        switch
                        @change="updateData"
                    >
                        <span style="margin-left: -4px">Horizontally</span>
                    </b-form-checkbox>
                    <!--                    <b-form-checkbox-->
                    <!--                        v-model="alignVertically"-->
                    <!--                        class="ml-2"-->
                    <!--                        name="show-dots-right"-->
                    <!--                        size="sm"-->
                    <!--                        switch-->
                    <!--                        @change="updateData"-->
                    <!--                    >-->
                    <!--                        <span style="margin-left: -4px">Vertically</span>-->
                    <!--                    </b-form-checkbox>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { MatchingDataSettings } from '../../types/matching'

interface Data {
    showDotsLeft: boolean
    showDotsRight: boolean
    alignHorizontally: boolean
    alignVertically: boolean
}

export default defineComponent({
    name: 'MatchingDots',
    props: {
        item: {
            type: Object as PropType<MatchingDataSettings>,
            required: true,
        },
    },
    data(): Data {
        return {
            showDotsLeft: this.item.show_dots_left,
            showDotsRight: this.item.show_dots_right,
            alignHorizontally: this.item.align_dots_horizontally,
            alignVertically: this.item.align_dots_vertically,
        }
    },
    methods: {
        updateData() {
            this.$emit('updateData', {
                show_dots_left: this.showDotsLeft,
                show_dots_right: this.showDotsRight,
                align_dots_horizontally: this.alignHorizontally,
                align_dots_vertically: this.alignVertically,
            })
        },
    },
})
</script>
