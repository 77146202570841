<template>
    <div class="card-body p-2">
        <div class="mt-2">
            <b-form-checkbox
                id="show_top_word"
                v-model="wordBankVisible"
                class="subtitle font-weight-bold text-muted"
                name="show_top_word"
                value="1"
                unchecked-value="0"
                @input="updateData"
            >
                Include Call List
            </b-form-checkbox>

            <div class="form-group mt-2">
                <label class="f-14">Sort Words</label>
                <b-form-select
                    v-model="wordBankSort"
                    :options="{
                        rand: 'Random',
                        natural: 'In Order',
                        alpha: 'Alphabetically',
                    }"
                    @change="updateData"
                ></b-form-select>
            </div>
        </div>
        <div class="card-body mt-2 p-0">
            <fieldset class="fieldset">
                <legend>Text</legend>
            </fieldset>
            <div class="row w-100 m-0 my-2 align-items-baseline">
                <div class="col-md-6 p-0 d-flex flex-column">
                    <label class="font-weight-bold mb-1 f-11 text-muted">Font Type</label>
                    <div>
                        <FontSelect
                            id="word-bank-font"
                            :key="'word-bank-font' + wordbankStyle.font"
                            class="mt-0 mb-0 pb-0 w-100"
                            field="word_bank_font"
                            size="sm"
                            @input="updateData"
                        />
                    </div>
                </div>
                <div class="col-md-3 p-0 pl-3 d-flex flex-column">
                    <ColorPicker v-model="wordBankColor" label="Font Color" />
                </div>
                <div class="col-md-3 p-0 pl-3 d-flex flex-column">
                    <label class="mb-1 f-11 text-muted d-flex justify-content-between">
                        <div class="font-weight-bold text-nowrap">Font Size</div>
                        <div class="text-secondary">
                            {{ parseInt(wordbankStyle.font_size) }}
                        </div>
                    </label>
                    <div class="d-flex f-10 align-items-center mb-0 mt-1">
                        <b-form-input
                            class="flex-glow-1"
                            type="range"
                            step="1"
                            min="10"
                            max="32"
                            :value="wordbankStyle.font_size"
                            @input="
                                $store.dispatch('document/setWordbankStyle', {
                                    font_size: Number($event),
                                })
                            "
                            @change="updateData"
                        ></b-form-input>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import FontSelect from '../../widgets/font-select.vue'
import ColorPicker from '../ColorPicker.vue'

export default defineComponent({
    name: 'BingoCallList',
    components: {
        ColorPicker,
        FontSelect,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            itemData: {
                ...this.data,
            },
        }
    },
    computed: {
        ...mapGetters({
            items: 'document/documentItems',
            wordbank: 'document/wordbank',
            wordbankStyle: 'document/wordbankStyle',
            style: 'document/documentStyle',
        }),
        wordBankVisible: {
            get() {
                return this.wordbank?.visible
            },
            set(value) {
                this.$store.dispatch('document/setWordbank', {
                    visible: Number(value),
                })
                this.$store.dispatch('document/storeDocumentState')
            },
        },
        wordBankSort: {
            get() {
                return this.wordbank?.sort
            },
            set(value) {
                this.$store.dispatch('document/setWordbank', {
                    sort: value,
                })
                this.$store.dispatch('document/storeDocumentState')
            },
        },
        wordBankBorderStyle: {
            get() {
                return this.document.word_bank_border_style
            },
            set(value) {
                this.$store.dispatch('document/setWordbankStyle', {
                    border_style: value,
                })
                this.$store.dispatch('document/storeDocumentState')
            },
        },
        wordBankColor: {
            get() {
                return this.wordbankStyle.color
            },
            set(value) {
                this.$store.dispatch('document/setWordbankStyle', {
                    color: value,
                })
                this.$store.dispatch('document/storeDocumentState')
            },
        },
    },
    methods: {
        updateData() {
            this.$emit('change', this.itemData)
        },
    },
})
</script>

<style scoped>
.overflow-visible {
    overflow: unset;
}
</style>
