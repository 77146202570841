<template>
    <div id="add-question" class="mt-2">
        <b-card class="border-0" no-body>
            <b-card-header class="bg-primary">
                <div class="text-center text-white font-weight-bold">Add Question</div>
            </b-card-header>
            <b-card-body class="py-1">
                <p class="font-weight-bold mb-0 ml-2">Activities</p>
                <div class="d-flex flex-wrap">
                    <div
                        v-for="option in documentTypes"
                        :key="'wstm-' + option.key"
                        class="type-wrapper d-flex flex-column text-left mb-1 mx-2"
                    >
                        <button class="type p-0 mt-1 rounded" @click="$emit('on-select-activity', option.key)">
                            <span class="img-container">
                                <img :src="'/img/worksheets/' + option.key + '.jpg'" />
                            </span>
                        </button>
                        <span class="type-button-text font-weight-bold">{{ option.name }}</span>
                    </div>
                </div>

                <p class="font-weight-bold mb-0 ml-2 mt-1">Elements</p>
                <div class="d-flex flex-wrap">
                    <div
                        v-for="option in elementTypes"
                        :key="'wstm-' + option.key"
                        class="type-wrapper d-flex flex-column text-left mb-1 mx-2"
                    >
                        <button class="type p-0 mt-1 rounded" @click="$emit('on-select-activity', option.key)">
                            <span class="img-container">
                                <img :src="'/img/elements/' + option.key + '.jpg'" />
                            </span>
                        </button>
                        <span class="type-button-text font-weight-bold">{{ option.name }}</span>
                    </div>
                </div>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { element_types } from '../../objects/Document'

export default defineComponent({
    name: 'AddQuestion',
    data() {
        return {
            elementTypes: element_types,
        }
    },
    computed: {
        ...mapGetters({
            documentTypes: 'document/allActivities',
        }),
    },
})
</script>

<style lang="scss">
@import 'Scss/base.scss';
@import 'Scss/runtime.scss';

.type-wrapper {
    user-select: none;
    cursor: pointer;
    .type {
        cursor: pointer;
        width: 130px;
        min-width: 120px;
        overflow: hidden;

        span.img-container {
            display: flex;
            height: 60px;
            overflow: hidden;
            box-shadow: 0 0px 3px $gray-500;
            transition: box-shadow 0.2s ease-out;
            background-color: white;
            justify-content: center;
            align-items: center;

            img {
                width: 100%;
                display: block;
                margin: auto;
                user-select: none;
            }
        }
    }
    .type-button-text {
        display: block;
        position: relative;
        font-size: 10px;
    }

    &:hover,
    &:focus {
        .type {
            outline: none;
            box-shadow: 0 0px 10px $gray-600;
        }
        .type-button-text {
            color: $primary;
        }
    }
}
</style>
