// We have to use deprecated functions until we migrate to Vue 3.
import { DirectiveOptions, VNode } from 'vue'

const ClickOutside: DirectiveOptions = {
    bind(el: any, binding: any, vnode: VNode) {
        el.clickOutsideEvent = (event: Event) => {
            if (!(el == event.target || el.contains(event.target as Node))) {
                if (binding.expression) {
                    // @ts-ignore
                    vnode.context[binding.expression](event)
                }
            }
        }
        document.body.addEventListener('click', el.clickOutsideEvent)
    },
    componentUpdated(el: any) {
        document.body.removeEventListener('click', el.clickOutsideEvent)

        document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unbind(el: any) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
    },
}

export default ClickOutside
