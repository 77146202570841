<template>
    <div>
        <div>
            <div v-for="(option, index) in options" :key="index" class="d-flex align-items-center mb-2 justify-between">
                <b-input-group>
                    <b-input-group-prepend>
                        <b-button class="multi-option-handle" size="sm" variant="secondary" tabindex="-1">
                            <b-icon icon="grip-horizontal"></b-icon>
                        </b-button>
                    </b-input-group-prepend>

                    <b-form-input
                        :id="'boolean-choice-item-' + index"
                        :ref="'b-choice-item-' + index"
                        :placeholder="isListItems ? `Item ${parseInt(index) + 1}` : 'Option'"
                        size="sm"
                        :value="option.answer"
                        disabled
                    ></b-form-input>
                    <span v-if="includeAnswerCheck" class="d-flex ml-2">
                        <b-form-checkbox
                            :id="`boolean-correct-checkbox-${index}`"
                            :key="`option-${index}`"
                            v-model="option.correct"
                            name="options"
                            class="f-14 text-muted ml-1 mr-n2"
                            switch
                            :value-field="option.correct"
                            @change="updateCorrectOption(index)"
                        ></b-form-checkbox>
                    </span>
                </b-input-group>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'BooleanOptions',
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        focusIndex: {
            type: Number,
            default: -1,
        },
        hasAnswer: {
            type: Boolean,
            default: true,
        },
        isListItems: {
            type: Boolean,
            default: false,
        },
        isBoolean: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            options: [],
            includeAnswerCheck: this.hasAnswer ?? true,
            hasBooleanOptions: this.isBoolean ?? false,
        }
    },
    watch: {
        options: {
            handler() {
                this.$emit('input', this.options)
            },
            deep: true,
        },
    },
    mounted() {
        this.options = this.value
    },
    methods: {
        updateCorrectOption(index) {
            this.$nextTick(() => {
                this.options.map((item, itemIndex) => {
                    if (itemIndex !== index) {
                        item.correct = !item.correct
                    }
                    return item
                })
                this.$emit('input', this.options)
            })
        },
    },
})
</script>

<style lang="scss" scoped>
.delete-button {
    background: #efefef;
    border: 1px solid #ced4da;
}
</style>
