export const removeSearchPair = (imageId: string): void => {
    const terms = localStorage.getItem('search_pair')
    let items = terms ? JSON.parse(terms) : []
    const searchPair = items.find((item: any) => item.imageId === imageId)
    if (searchPair) {
        items = items.filter((item: any) => item.imageId !== imageId)
    }
    localStorage.setItem('search_pair', JSON.stringify(items))
}

export const addSearchPairToLocalHistory = (imageId: string, search?: string): void => {
    if (!search) return
    const previous = localStorage.getItem('search_pair')
    const items = previous ? JSON.parse(previous) : []
    const hasSearchTerm = items.find((item: any) => item.searchTerm === search)
    const hasImageId = items.find((item: any) => item.imageId === imageId)
    if (hasSearchTerm || hasImageId) {
        hasSearchTerm ? (hasSearchTerm.imageId = imageId) : (hasImageId.searchTerm = search)
        localStorage.setItem('search_pair', JSON.stringify(items))
        return
    }
    items.push({
        searchTerm: search,
        imageId: imageId,
    })
    localStorage.setItem('search_pair', JSON.stringify(items))
}
