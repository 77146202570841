<template>
    <!--    TODO: refactor this to use IconButton component-->
    <b-button v-b-tooltip.hover variant="danger" size="sm" title="Start Over" @click="$emit('startOver')">
        <b-icon-arrow-clockwise />
    </b-button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'StartOverButton',
})
</script>

<style scoped>
.btn:focus {
    box-shadow: none;
}
</style>
