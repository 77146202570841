<template>
    <div>
        <div class="card-body py-1">
            <div class="row m-0">
                <b-form-checkbox
                    id="checkbox-2"
                    v-model="direction"
                    class="subtitle font-weight-bold text-muted"
                    name="checkbox-2"
                    :value="true"
                    :unchecked-value="false"
                >
                    Directions
                </b-form-checkbox>
            </div>

            <div v-if="direction" id="custom-editor" ref="editorContainer" @drop="(e) => e.preventDefault()">
                <VueEditor
                    :ref="`widget_${index}`"
                    v-model="subtitleInput"
                    placeholder="Write your directions here."
                    :editor-toolbar="customToolbar"
                    :editor-options="editorSettings"
                    @text-change="updateData"
                    @focus="onEditorFocus"
                    @blur="onEditorBlur"
                ></VueEditor>
            </div>

            <label class="f-11 text-muted font-weight-bold my-2">
                <div>Checklist Items</div>
            </label>
            <MultiOptions
                v-if="listItems"
                v-model="listItems"
                class="mb-1"
                :has-answer="false"
                :focus-index="focusIndex"
                :is-list-items="true"
                @clearFocusIndex="clearFocusIndex"
            ></MultiOptions>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { VueEditor } from 'vue2-editor'
import MultiOptions from '../MultiOptions.vue'

export default defineComponent({
    name: 'CheckList',
    components: {
        VueEditor,
        MultiOptions,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        clickedIndex: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            direction: this.data.direction ?? true,
            customToolbar: [['bold', 'italic', 'underline'], [], []],
            editorSettings: {
                formats: ['bold', 'italic', 'underline', 'script'],
                modules: {
                    keyboard: {
                        bindings: {
                            tab: {
                                key: 9,
                                handler: function () {
                                    return true
                                },
                            },
                        },
                    },
                },
            },
            itemData: {
                ...this.data,
            },
            focusIndex: null,
        }
    },
    computed: {
        listItems: {
            get() {
                return this.itemData.options
            },
            set(choices) {
                this.itemData.options = choices
                this.$emit('change', this.itemData)
            },
        },
        subtitleInput: {
            get() {
                return this.itemData.subtitle
            },
            set(value) {
                this.itemData.subtitle = value
                this.$emit('change', this.itemData)
            },
        },
    },
    watch: {
        direction: function () {
            this.updateDirection()
        },
        clickedIndex(val) {
            if (val != null) this.updateFocusIndex(val)
        },
    },
    mounted() {
        if (this.clickedIndex != null) this.updateFocusIndex(this.clickedIndex)
        else this.$refs[`widget_${this.index}`]?.quill.focus()
    },
    methods: {
        updateData($event = null) {
            if ($event) {
                if ($event.ops[$event.ops.length - 1].insert === '\t') {
                    this.focusIndex = 0
                }
            } else {
                this.$emit('change', this.itemData)
            }
        },
        updateOption(index, value) {
            this.itemData.options[index].answer = value
            this.updateData()
        },
        updateDirection() {
            this.itemData.direction = this.direction
            this.updateData()
        },
        removeOption(index) {
            this.itemData.options.splice(index, 1)
            this.updateData()
        },
        addOption() {
            this.itemData.options.push({
                answer: '',
                correct: false,
            })
            this.updateData()
        },
        getRightChoice() {
            return {
                answer: this.itemData.term,
                correct: true,
            }
        },
        clearFocusIndex() {
            this.focusIndex = null
        },
        updateFocusIndex(val) {
            this.focusIndex = val
        },
        onEditorBlur() {
            this.$refs.editorContainer.classList.remove('active')
        },
        onEditorFocus() {
            this.$refs.editorContainer.classList.add('active')
        },
    },
})
</script>

<style lang="scss" scoped>
@import 'Scss/base.scss';
.subtitle {
    font-size: $font-size-sm;
}
.delete-button {
    background: #efefef;
    border: 1px solid #ced4da;
}
</style>
