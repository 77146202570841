<template>
    <div id="occupation-form" class="mb-3">
        <div class="grid-2">
            <b-form-radio v-for="(value, key) in occupations" :key="'occupations-' + key" v-model="occupation" :value="key">
                {{ value }}
            </b-form-radio>
        </div>
        <div v-if="error" class="invalid-feedback d-block" v-html="error"></div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState, mapGetters } from 'vuex'

export default defineComponent({
    name: 'Occupation',
    props: {
        error: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            occupation: null,
            occupations: {
                t: 'Teacher',
                h: 'Homeschooler',
                p: 'Parent',
                o: 'Other',
            },
        }
    },
    computed: {
        ...mapState(['user']),
        ...mapGetters({ isLoggedIn: 'user/isLoggedIn' }),
    },
    created() {
        this.occupation = this.user.user.occupation

        // watch our occupation
        this.$watch('occupation', () => {
            // if the user is logged in
            this.$store.dispatch('user/updateUser', { occupation: this.occupation })
        })
    },
})
</script>
