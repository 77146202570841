<template>
    <div>
        <Draggable v-model="options" v-bind="{ handle: '.-option-handle', animation: 500 }" @end="updateData">
            <div v-for="(option, index) in options" :key="index" class="mb-2">
                <div class="d-flex align-items-center mb-2 justify-between">
                    <span class="f-14 font-weight-bold mr-2 text-right" style="width: 24px">{{ index + 1 }}.</span>
                    <b-input-group>
                        <b-input-group-prepend>
                            <b-button class="-option-handle" size="sm" variant="secondary" tabindex="-1">
                                <b-icon icon="grip-horizontal"></b-icon>
                            </b-button>
                        </b-input-group-prepend>

                        <b-form-input
                            :id="'bingo-word-item-' + index"
                            :ref="`bingo-word-item-${index}`"
                            v-model="option.term"
                            placeholder="Enter your word"
                            size="sm"
                            @input="updateData(index, $event)"
                            @keydown.enter="focusNext(index)"
                        ></b-form-input>

                        <b-input-group-append>
                            <InlineImageBtn
                                :ref="`bingo-word-image-btn-${index}`"
                                :image-id="option.term_image"
                                :input-index="index"
                                column="term"
                                btn-class="rounded-right-small"
                                @deleted="clearInlineImage(index)"
                            />
                        </b-input-group-append>
                    </b-input-group>
                    <b-button
                        size="sm"
                        class="d-flex align-items-center ml-2"
                        tabindex="-1"
                        variant="danger"
                        @click="removeOption(index)"
                    >
                        <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" height="20" width="20">
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                        </svg>
                    </b-button>
                </div>
                <div>
                    <b-form-input
                        v-if="option.prompt"
                        :ref="'bingo-word-prompt-' + index"
                        v-model="option.definition"
                        placeholder="Add prompt for call list"
                        size="sm"
                        :style="{
                            width: `${optionWidth}`,
                            marginLeft: '3.8438rem',
                        }"
                        @input="updateData(index, $event)"
                        @keydown.enter="focusNext(index, true)"
                    ></b-form-input>
                </div>
            </div>
        </Draggable>
        <div class="d-flex my-2">
            <b-input-group :style="{ width: `${optionWidth}`, marginLeft: '3.8438rem' }">
                <b-input
                    ref="add-new--option"
                    v-model="newOption"
                    size="sm"
                    type="text"
                    list="add-new--option"
                    placeholder="+ Add new word"
                />
                <b-input-group-append>
                    <InlineImageBtn
                        :input-index="options.length"
                        column="term"
                        btn-class="rounded-right-small"
                        @click="addOption"
                    />
                </b-input-group-append>
            </b-input-group>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import Draggable from 'vuedraggable'
import { mapGetters } from 'vuex'
import { debounce } from 'lodash'
import InlineImageBtn from './AddInlineImageButton.vue'
import InlineImagesMixin from '../mixins/InlineImages'

export default defineComponent({
    name: 'BingoWordItems',
    components: {
        Draggable,
        InlineImageBtn,
    },
    mixins: [InlineImagesMixin],
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        prompt: {
            type: [Boolean, Number],
            default: false,
        },
    },
    data() {
        return {
            newOption: '',
            options: [],
            optionWidth: '100%',
        }
    },
    computed: {
        ...mapGetters({
            currentWidget: 'document/currentWidget',
            style: 'document/documentStyle',
            bingoItems: 'document/bingoItems',
            bingo: 'document/bingo',
        }),
        dimension() {
            return this.bingo.dimension
        },
    },
    watch: {
        newOption(val) {
            if (val.trim()) {
                this.addOption(val.trim())
            }
        },
        style: {
            deep: true,
            handler() {
                this.updateData()
            },
        },
        value() {
            this.options = this.value
        },
        currentWidget: {
            handler(val) {
                if (val.openBingoWords && this.$refs[`bingo-word-item-${val.focusedItem.index}`] !== undefined) {
                    this.$refs[`bingo-word-item-${val.focusedItem.index}`][0]?.$el?.focus()
                }
            },
        },
    },
    mounted() {
        this.options = this.value
        this.$nextTick(() => {
            if (this.$refs['bingo-word-item-0']) {
                let width = 0
                const firstInput = this.$refs['bingo-word-item-0'][0]?.$el
                const firstInputImageBtn = this.$refs['bingo-word-image-btn-0'][0]?.$el
                if (firstInput) {
                    width += firstInput.clientWidth
                }
                if (firstInputImageBtn) {
                    width += firstInputImageBtn.clientWidth
                }
                this.optionWidth = `${width}px`
            } else {
                this.optionWidth = `100%`
            }
        })
    },
    methods: {
        updateData() {
            this.emitEvent()
        },
        emitEvent: debounce(function () {
            this.$emit('input', this.options)
        }, 500),
        removeOption(index) {
            if (`${this.options[index]?.term_image}`.length) {
                this.options[index].term_image = ''
            }
            this.options.splice(index, 1)
            this.$emit('input', this.options)
        },
        addOption(val = '') {
            this.options.push({
                term: val,
                term_image: null,
                prompt: this.prompt,
                definition: '',
            })

            this.$emit('input', this.options)

            this.$nextTick(() => {
                let index = this.options.length - 1
                this.$refs['bingo-word-item-' + index][0].$el.focus()
                this.newOption = ''
            })
        },
        focusNext(index, isPrompt = false) {
            if (this.options[index].prompt && !isPrompt) {
                this.$refs['bingo-word-prompt-' + index][0].$el?.focus()
            } else if (index < this.options.length - 1) {
                this.$refs['bingo-word-item-' + (index + 1)][0].$el?.focus()
            } else {
                this.$refs['add-new--option'].focus()
            }
        },
        clearInlineImage(index) {
            this.options[index].term_image = ''
            this.updateData()
        },
    },
})
</script>
