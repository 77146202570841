<template>
    <div class="d-flex spread-input">
        <span v-for="(v, i) of inputValue" :key="i">
            <b-input
                :ref="'input' + i"
                v-model="v.value"
                class="text-center mr-2 font-weight-bold p-0"
                :class="{
                    'border-primary-1': v === '',
                }"
                minlength="1"
                maxlength="1"
                type="text"
                size="sm"
                aria-label="Top word letter input"
                :style="{ maxWidth: '30px' }"
                @focus="cursorIndex = i"
                @keyup="handleKeyEvent"
                @change="$emit('change', $event)"
            />
        </span>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
    name: 'SpreadInput',
    props: {
        length: {
            type: [String, Number],
            required: true,
        },
        value: {
            type: String,
            default: '',
        },
    },
    data: function () {
        return {
            cursorIndex: 0,
            inputValue: new Array(Number(this.length)).fill({ value: '' }),
        }
    },
    computed: {
        ...mapGetters({
            currentWidget: 'document/currentWidget',
        }),
    },
    watch: {
        length(val) {
            let inputs = this.inputValue
            this.inputValue = new Array(Number(val)).fill('').map((_, index) => {
                return inputs.length > index ? { value: inputs[index].value } : { value: '' }
            })

            this.updateData()
            this.$nextTick(() => this.focusInput(this.value.length))
        },
        currentWidget: {
            // immediate: true,
            handler(val) {
                if (val.bingoCardSetup && val.focusedItem && this.$refs[`input${val.focusedItem.index}`]) {
                    this.$refs[`input${val.focusedItem.index}`][0].$el?.focus()
                }
            },
        },
    },
    mounted() {
        if (this.value) {
            const letters = this.value?.split('') || []
            this.inputValue = new Array(Number(this.length)).fill('').map((_, index) => {
                return letters.length > index && letters[index] !== ' ' ? { value: letters[index] } : { value: '' }
            })
        }

        this.$nextTick(() => this.focusInput(this.cursorIndex))
    },
    methods: {
        updateData() {
            this.emitEvent('input', this.inputValue.map((letter) => (letter.value ? letter.value : ' ')).join(''))
        },
        handleInput(keyData) {
            keyData && this.onType(keyData)
        },
        handleKeyEvent({ key }) {
            if (key === 'Backspace' || key === 'Delete') {
                this.onDelete()
            } else if (key === 'ArrowLeft') {
                this.prevInput()
            } else if (key === 'ArrowRight') {
                this.nextInput()
            } else if (key.length === 1) {
                this.onType(key)
            }
        },
        onType(keyData) {
            this.inputValue[this.cursorIndex].value = keyData
            this.updateData()
            this.nextInput()
        },
        onDelete() {
            if (!this.inputValue[this.cursorIndex].value) {
                this.inputValue[this.cursorIndex].value = ''
                this.updateData()
            }

            this.prevInput()
        },
        nextInput() {
            if (this.cursorIndex < this.length - 1) {
                this.focusInput(this.cursorIndex + 1)
            }
        },
        prevInput() {
            if (this.cursorIndex > 0) {
                this.focusInput(this.cursorIndex - 1)
            }
        },
        focusInput(index) {
            if (this.$refs[`input${index}`]) {
                const inputRef = this.$refs[`input${index}`][0]
                inputRef && inputRef.focus()
            }
        },
        emitEvent(eventName, payload) {
            this.$emit(eventName, payload)
        },
    },
})
</script>

<style scoped lang="scss">
.spread-input {
    display: flex;

    div {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        input {
            max-width: 30px;
            padding: 11.5px 8px;
            font-size: 20px;
            border-radius: 3px;
            border: 1px solid #cecece;
            text-align: center;
        }
        span {
            display: block;
            flex: 1;
            text-align: center;
        }
    }
}
</style>
