<template>
    <div class="card-body pb-1 pt-0">
        <div class="alert alert-primary d-flex mb-0">
            <svg height="20" width="20" class="domain-text-color flex-shrink-0 mr-2" viewBox="0 0 20 20" fill="currentColor">
                <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                    clip-rule="evenodd"
                />
            </svg>
            <span class="f-13 font-weight-bold">Your worksheet will continue on the following page.</span>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'PageBreak',
})
</script>
