<template>
    <b-modal id="onboarding-complete" hide-footer hide-header centered @shown="recheckSubscription">
        <div>
            <div class="row justify-content-center px-5">
                <div class="col-md-4 px-1">
                    <b-progress height="0.4em" value="100" variant="primary"></b-progress>
                </div>
                <div class="col-md-4 px-1">
                    <b-progress height="0.4em" value="100" variant="primary"></b-progress>
                </div>
                <div class="col-md-4 px-1 position-relative">
                    <b-progress height="0.4em" value="100" variant="primary"></b-progress>
                    <svg
                        class="position-absolute"
                        style="top: -5px; right: -18px"
                        width="27"
                        height="17"
                        viewBox="0 0 27 17"
                        fill="none"
                    >
                        <path
                            d="M0.0893076 7.61345L13.5243 13.9774V1.24949L0.0893076 7.61345ZM11.5232 10.8166L4.75621 7.61345L11.5232 4.41026L11.5232 10.8166Z"
                            fill="#715DD6"
                        />
                        <path
                            d="M15.6457 9.7772L23.5511 9.7772C24.2441 9.7772 24.8027 10.3358 24.8027 11.0288V11.8632L26.3018 11.8632L26.3018 11.0288C26.3018 9.51557 25.0643 8.27813 23.5511 8.27813L15.6457 8.27813V9.7772Z"
                            fill="#715DD6"
                        />
                        <path
                            d="M16.48 2.62128L15.6457 2.62128L15.6457 4.12034L16.48 4.12034C17.9933 4.12034 19.2307 2.88291 19.2307 1.3697V0.535312L17.7246 0.542383L17.7246 1.37677C17.7246 2.05559 17.1589 2.62128 16.48 2.62128Z"
                            fill="#715DD6"
                        />
                        <path
                            d="M17.8943 11.1066L15.6457 11.1066L15.6457 12.6056H17.8943C18.5872 12.6056 19.1458 13.1642 19.1458 13.8572V16.1341L20.6449 16.1341L20.6449 13.8572C20.6378 12.3369 19.4075 11.1066 17.8943 11.1066Z"
                            fill="#715DD6"
                        />
                        <path
                            d="M20.7227 5.4497H15.6457V6.94877L20.7227 6.94877C22.2359 6.94877 23.4733 5.71133 23.4733 4.19813L23.4733 1.94953L21.9743 1.94953V4.19813C21.9672 4.88402 21.4015 5.4497 20.7227 5.4497Z"
                            fill="#715DD6"
                        />
                    </svg>
                </div>
            </div>

            <h6 class="font-weight-bold text-center mb-3 mt-3">Success! Your free account is complete.</h6>

            <div class="message-box text-center p-3">
                <p>SPECIAL BONUS: Full Features Unlocked!</p>
                <svg width="89" height="85" viewBox="0 0 89 85" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M86 42.5C86 64.1867 67.5513 82 44.5 82C21.4487 82 3 64.1867 3 42.5C3 20.8133 21.4487 3 44.5 3C67.5513 3 86 20.8133 86 42.5Z"
                        fill="#FFE976"
                        stroke="#EA289C"
                        stroke-width="6"
                    />
                    <path
                        d="M47.9468 38.5934H66.1201V64C66.1201 65.6568 64.777 67 63.1201 67H47.9468V38.5934Z"
                        fill="#EA289C"
                    />
                    <path
                        d="M22.8799 38.5934H41.0532V67H25.8799C24.223 67 22.8799 65.6568 22.8799 64V38.5934Z"
                        fill="#EA289C"
                    />
                    <path
                        d="M21 28.2968C21 26.6399 22.3431 25.2968 24 25.2968H65C66.6569 25.2968 68 26.6399 68 28.2968V35.5715H21V28.2968Z"
                        fill="#EA289C"
                    />
                    <path
                        d="M41.9 22.7555C40.8538 19.9899 37.0779 17.1301 32.5026 15.9005C31.1646 15.5409 29.7894 15.35 28.8625 15.9312C27.8323 16.5772 26.718 18.0757 27.6565 19.1143C29.0093 20.6112 31.8036 22.1696 34.0476 23.1347C35.7975 23.8874 38.3151 23.9848 39.9188 24.7688"
                        stroke="#EA289C"
                        stroke-width="5"
                        stroke-linecap="round"
                    />
                    <path
                        d="M46.7626 25.2363C49.8005 25.5228 54.1073 23.4765 57.2359 20.0298C58.1507 19.0219 58.9241 17.9087 58.7817 16.8512C58.6234 15.676 57.7046 14.0588 56.3277 14.4257C54.3432 14.9545 51.6776 16.7102 49.8048 18.2441C48.3442 19.4402 47.1641 21.5872 45.7376 22.6427"
                        stroke="#EA289C"
                        stroke-width="5"
                        stroke-linecap="round"
                    />
                    <ellipse cx="44.8131" cy="25.9011" rx="5.01333" ry="4.83517" fill="#EA289C" />
                </svg>
                <div class="mt-2 f-13">
                    <p>
                        Enjoy full, free access to
                        <strong>all features and sister sites</strong>
                        for
                        <strong>30 days.</strong>
                    </p>

                    <p>
                        Create worksheets, crosswords, word searches, bingo, and more! Explore every activity maker and
                        publish unlimited documents.
                    </p>

                    <p>After 30 days, your account will revert to free features only.</p>

                    <p>
                        Discover
                        <a
                            href="https://myworksheetmaker.groovehq.com/help/what-are-your-other-sites-do-you-have-other-builders-for-teachers"
                            class="text-danger underline"
                            target="_blank"
                            rel="nofollow"
                        >
                            everything you can do.
                        </a>
                    </p>

                    <strong>Happy Creating! We can’t wait to see what you make.</strong>
                    😍️
                </div>
            </div>
            <div class="d-flex mt-2 justify-content-center align-items-center">
                <button v-if="onPublishing" class="btn btn-primary btn-sm mr-2" @click="proceedToPrint(true)">
                    Publish Worksheet
                </button>
                <button class="btn btn-sm" :class="onPublishing ? 'btn-secondary' : 'btn-primary'" @click="proceedToPrint()">
                    Back to Builder
                </button>
            </div>
        </div>
    </b-modal>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState, mapGetters } from 'vuex'
import { dangerToast } from '../helpers/toasts'

export default defineComponent({
    name: 'OnboardingComplete',
    data() {
        return {
            role: null,
            step: 1,
            transactional: true,
        }
    },
    computed: {
        ...mapGetters({
            isLoggedIn: 'user/isLoggedIn',
            hasPlan: 'user/hasPlan',
            worksheet: 'document/worksheet',
            isWorksheet: 'document/isWorksheet',
            hasPlans: 'subscription/hasPlans',
            onPublishing: 'document/onpublishing',
        }),
        ...mapState(['user', 'document', 'subscription']),
    },
    methods: {
        proceedToPrint(print = false) {
            if (this.hasPlan || this.hasPlans) {
                this.$bvModal.hide('onboarding-complete')
                if (print) this.$bvModal.show('printable-modal')
            }
        },
        recheckSubscription() {
            this.$store.dispatch('subscription/getSubscription').then(() => {
                if (!(this.hasPlan || this.hasPlans)) {
                    this.$bvToast.toast(
                        'There might be a problem with your subscripton. Please try to refresh page if you notice any errors.',
                        dangerToast,
                    )
                }
            })
        },
    },
})
</script>

<style scoped>
.message-box {
    background: rgba(113, 93, 214, 0.04);
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 4px;
}

.underline {
    text-decoration: underline;
}

.h-2 {
    height: 1em;
}

.w-2 {
    width: 1em;
}
</style>
