<template>
    <div>
        <div class="d-flex justify-content-end">
            <button
                v-b-tooltip.hover
                class="btn btn-primary rounded btn-sm d-flex align-items-center"
                title="Style"
                @click="toggleVisible"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="bi bi-palette-fill mr-1"
                    viewBox="0 0 16 16"
                >
                    <path
                        d="M12.433 10.07C14.133 10.585 16 11.15 16 8a8 8 0 1 0-8 8c1.996 0 1.826-1.504 1.649-3.08-.124-1.101-.252-2.237.351-2.92.465-.527 1.42-.237 2.433.07zM8 5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm4.5 3a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM5 6.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm.5 6.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
                    />
                </svg>
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    stroke="currentColor"
                    fill="currentColor"
                >
                    <defs>
                        <path
                            id="text-path-0"
                            d="M 3.7090001106262207 15.151793241500854 Q 8.00025224685669 0.847618818283081 12.291504383087158 15.151793241500854"
                        />
                    </defs>
                    <rect
                        x="1"
                        y="1"
                        width="14"
                        height="14"
                        style="fill: none; stroke-miterlimit: 7; stroke-linejoin: round"
                    />
                    <text
                        style="font-family: Arial, sans-serif; font-size: 12.8px; stroke-miterlimit: 1; white-space: pre"
                        transform="matrix(0.438371, 0.898794, -0.898794, 0.438371, 15.445608, 2.922325)"
                    >
                        <textPath xlink:href="#text-path-0">A</textPath>
                    </text>
                </svg>
            </button>
        </div>

        <div
            v-if="show"
            v-on-clickaway="close"
            class="position-absolute border-primary-left body-style-settings bg-white f-14 rounded shadow"
        >
            <div class="position-relative p-3">
                <svg
                    class="text-muted position-absolute pointer"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    @click="toggleVisible"
                >
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>

                <h3 class="f-15 font-weight-bold">Body Font Styling</h3>

                <div class="d-flex align-items-center justify-content-between">
                    <div class="flex-grow-1">
                        <label class="mb-0 f-11 text-muted d-flex justify-content-between">
                            <div class="font-weight-bold">Font Size</div>
                            <div class="text-secondary">
                                {{ style.font_size }}
                            </div>
                        </label>
                        <b-form-input
                            v-model="font_size"
                            type="range"
                            step="0.5"
                            min="10"
                            max="64"
                            :value="style.font_size"
                        ></b-form-input>
                    </div>

                    <div class="ml-3 flex-grow-1">
                        <label class="mb-0 f-11 text-muted d-flex justify-content-between">
                            <div class="font-weight-bold">Item Spacing</div>
                            <div class="text-secondary">{{ style.item_spacing }}</div>
                        </label>
                        <b-form-input
                            v-model="item_spacing"
                            type="range"
                            :min="0.5"
                            :max="8"
                            :step="0.1"
                            :value="style.item_spacing"
                        ></b-form-input>
                    </div>

                    <div class="ml-3 flex-grow-1">
                        <label class="mb-0 f-11 text-muted d-flex justify-content-between">
                            <div class="font-weight-bold">Line Height</div>
                            <div class="text-secondary">
                                {{ style.font_space }}
                            </div>
                        </label>
                        <b-form-input
                            v-model="font_space"
                            type="range"
                            :min="1"
                            :max="3.5"
                            :step="0.1"
                            :value="style.font_space"
                        ></b-form-input>
                    </div>
                </div>

                <div class="d-flex justify-content-between">
                    <div class="font-size-wrapper w-75 mt-1">
                        <label class="font-weight-bold f-11 text-muted d-block mb-1">Font Type</label>
                        <FontSelect
                            id="font-body"
                            :key="'title-font' + style.font"
                            field="body_font"
                            :options="false"
                            size="sm"
                            class="mt-0 w-100"
                        />
                    </div>
                    <div class="ml-3">
                        <ColorPicker v-model="style.color" label="Font Color" @change="updateFontColor" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters, mapState } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway'
import FontSelect from '../../widgets/font-select.vue'
import ColorPicker from '../ColorPicker.vue'

export default defineComponent({
    name: 'BodyStyleSettings',
    components: {
        ColorPicker,
        FontSelect,
    },
    mixins: [clickaway],
    data() {
        return {
            show: false,
        }
    },
    computed: {
        ...mapState(['document', 'user']),
        ...mapGetters({
            style: 'document/documentStyle',
            isLoggedIn: 'user/isLoggedIn',
        }),
        font_space: {
            get() {
                return this.style.font_space
            },
            set(value) {
                this.$store.dispatch('document/setDocumentStyle', {
                    font_space: parseFloat(value),
                })

                this.$store.dispatch('document/paginateItems')

                this.updateData()
            },
        },
        font_size: {
            get() {
                return this.style.font_size
            },
            set(value) {
                this.$store.dispatch('document/setDocumentStyle', {
                    font_size: parseFloat(value),
                })

                this.$store.dispatch('document/paginateItems')

                this.updateData()
            },
        },
        item_spacing: {
            get() {
                return this.style.item_spacing
            },
            set(value) {
                this.$store.dispatch('document/setDocumentStyle', {
                    item_spacing: parseFloat(value),
                })

                this.$store.dispatch('document/paginateItems')

                this.updateData()
            },
        },
    },
    methods: {
        toggleVisible() {
            this.show = !this.show
            this.$emit('visible-changed', this.show)
        },
        updateData() {
            this.$emit('change', {
                non_item: true,
            })
        },
        close() {
            this.show = false
            this.$emit('visible-changed', this.show)
        },
        async updateFontColor() {
            await this.$store.dispatch('document/setDocumentStyle', {
                color: this.style.color,
            })
            await this.updateData()
        },
    },
})
</script>
