<template>
    <div class="card-body pb-1 pt-0">
        <div class="d-flex align-items-center">
            <div class="w-75 pr-4">
                <label class="font-weight-bold d-flex py-1 justify-content-between mb-0 f-11 text-muted">
                    <span>Height</span>
                    <span class="font-weight-normal">{{ Math.round(itemData.height * 10) / 10 }} in</span>
                </label>

                <b-form-input v-model="lineHeight" type="range" :min="0.125" :max="8.3" :step="0.01"></b-form-input>
            </div>

            <div class="w-25">
                <label class="font-weight-bold mb-0 f-11 text-muted">Box Outline</label>
                <b-form-checkbox
                    v-model="boxOutline"
                    class="f-14 text-muted ml-1 mr-n2 pb-3"
                    tabindex="-1"
                    name="box_outline"
                    switch
                ></b-form-checkbox>
            </div>
        </div>

        <BorderSettings v-if="itemData.box_outline" v-model="borderStyle" class="mt-2" collapsible />
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import BorderSettings from '../../widgets/BorderSettings.vue'

export default defineComponent({
    name: 'BlankSpace',
    components: { BorderSettings },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            itemData: {
                ...this.data,
            },
            showOptions: false,
        }
    },
    computed: {
        lineHeight: {
            get() {
                return this.itemData.height
            },
            set(value) {
                this.itemData.height = value
                this.updateData()
            },
        },
        boxOutline: {
            get() {
                return this.itemData.box_outline
            },
            set(value) {
                this.itemData.box_outline = value
                this.updateData()
                if (!value) {
                    this.showOptions = value
                }
            },
        },
        borderStyle: {
            get() {
                return {
                    border_color: this.itemData.line_color,
                    border_style: this.itemData.line_style,
                    border_width: this.itemData.border_width,
                }
            },
            set(value) {
                this.itemData.line_color = value.border_color
                this.itemData.line_style = value.border_style
                this.itemData.border_width = value.border_width

                this.updateData()
            },
        },
    },
    methods: {
        updateData() {
            this.$emit('change', this.itemData)
        },
    },
})
</script>
