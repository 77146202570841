<template>
    <div>
        <div class="card-body p-2 border rounded">
            <b-form-checkbox
                id="show_top_word"
                v-model="bingo.show_top_word"
                class="subtitle font-weight-bold text-muted"
                name="show_top_word"
                :value="1"
                :unchecked-value="0"
                @change="updateData"
            >
                Top Word
            </b-form-checkbox>
            <div class="d-flex justify-content-center align-items-center mx-4">
                <label for="dimension-select" class="mb-0">Dimensions:</label>
                <b-form-select
                    id="dimension-select"
                    name="dimension-select"
                    class="ml-2"
                    :value="bingo.dimension"
                    :options="bingoCardDimensions"
                    size="sm"
                    @change="updateBingoDimension($event)"
                ></b-form-select>
            </div>
            <div v-if="bingo.show_top_word" class="px-4 mt-2">
                <SpreadInput v-model="bingoTopWord" :length="cardDimension" @change="updateData"></SpreadInput>
            </div>
        </div>
        <div class="card-body p-2 border rounded mt-2">
            <BingoFreeSpaceForm @change="updateData"></BingoFreeSpaceForm>
        </div>

        <div class="card-body p-2 border rounded mt-2">
            <fieldset class="fieldset">
                <legend>Cards</legend>
            </fieldset>

            <div class="d-flex align-items-center">
                <label class="mb-0">
                    <span class="mr-2">Cards Needed:</span>
                </label>
                <b-form-select
                    v-model="bingoCardsNeeded"
                    :clearable="false"
                    item-value="key"
                    label="key"
                    class="w-50"
                    value-field="key"
                    text-field="key"
                    :options="bingoCardNumbers"
                    size="sm"
                    aria-label="Number of cards needed"
                    @change="updateData"
                ></b-form-select>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters, mapState } from 'vuex'
import SpreadInput from '../SpreadInput.vue'
import BingoFreeSpaceForm from '../BingoFreeSpaceForm.vue'

export default defineComponent({
    name: 'BingoCardSetup',
    components: {
        SpreadInput,
        BingoFreeSpaceForm,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            itemData: {
                ...this.data,
            },
        }
    },
    computed: {
        ...mapState(['user']),
        ...mapGetters({
            bingo: 'document/bingo',
            style: 'document/documentStyle',
        }),
        bingoTopWord: {
            get() {
                return this.bingo.top_word
            },
            set(newVal) {
                let updates = {
                    top_word: newVal,
                }
                this.$store.dispatch('document/setBingo', updates)
                this.$store.dispatch('document/storeDocumentState')
            },
        },
        bingoCardsNeeded: {
            get() {
                return this.bingo.num_of_cards
            },
            set(value) {
                this.$store.dispatch('document/setBingo', {
                    num_of_cards: Number(value),
                })
                this.$store.dispatch('document/storeDocumentState')
            },
        },
        cardDimension() {
            return this.bingo.dimension
        },
        bingoCardDimensions() {
            let options = {}
            for (let i = 3; i <= 7; i++) {
                options[i] = i + ' × ' + i
            }
            return options
        },
        bingoCardNumbers() {
            let options = []
            for (let i = 1; i <= 200; i++) {
                let option = {
                    key: i,
                    is_premium: i > this.user.ab_tests['premium-bingo-cards'] ? true : false,
                }
                options.push(option)
            }
            return options
        },
    },
    methods: {
        updateData() {
            this.$emit('change', this.itemData)
        },
        updateBingoDimension(value) {
            let updates = {
                dimension: value,
                free_spaces: [],
            }
            this.$store.dispatch('document/setBingo', updates)
            this.$store.dispatch('document/storeDocumentState')
            this.updateData()
        },
    },
})
</script>
