//vue instance
import Vue from 'vue'

import store from '../store/document'
import { mapState, mapGetters } from 'vuex'

var navData = {
    active_panel: 'header',
    accordion_focus: 'edit',
    current_url: window.location.pathname,
    online: navigator.onLine,
}

if (window.doc && document.is_published) {
    navData.active_panel = 'print'
}

export const Nav = new Vue({
    store,
    data: navData,
    computed: {
        ...mapState(['user', 'document']),
        ...mapGetters({
            isLoggedIn: 'user/isLoggedIn',
            worksheet: 'document/worksheet',
            isBingo: 'document/isBingo',
        }),
        showWordBank() {
            return ['fill_in_the_blank', 'word_scramble'].includes(this.worksheet.type) || this.isBingo
        },
        panels: function () {
            if (this.showWordBank) {
                return ['type', 'header', 'body', 'word-bank', 'print']
            } else {
                return ['type', 'header', 'body', 'print']
            }
        },
    },
    methods: {
        setPanel(panel, focus) {
            this.active_panel = panel
            if (focus) {
                this.accordion_focus = focus
            }
        },
        get(key) {
            if (typeof this[key] != 'undefined') {
                return this[key]
            }
        },
        set(key, value) {
            //update the window history if we're updating current_url
            if (key == 'current_url') {
                window.history.replaceState({}, '', '/' + value)
            }
            //set the local variable
            this[key] = value
        },
        setDefaultAccordion() {
            this.$nav.setPanel('home', 'home')
        },
    },
})
