<template>
    <div class="card-body pb-1 pt-0">
        <div class="d-flex align-items-center">
            <div>
                <label class="font-weight-bold mb-1 f-11 text-muted">Divider Width</label>
                <b-form-input
                    v-model="itemData.line_width"
                    type="range"
                    min="25"
                    max="100"
                    @input="updateData"
                ></b-form-input>
            </div>

            <div class="mx-3">
                <label class="font-weight-bold mb-1 f-11 text-muted">Line Thickness</label>
                <b-form-input
                    v-model="itemData.line_weight"
                    type="range"
                    min="1"
                    max="10"
                    @input="updateData"
                ></b-form-input>
            </div>

            <div>
                <ColorPicker v-model="itemData.line_color" label="Color" @change="updateData" />
            </div>
        </div>

        <div class="d-flex mt-2">
            <div class="flex-grow-1 mr-3">
                <label class="mb-0 f-11 text-muted d-flex justify-content-between">
                    <div class="font-weight-bold">Top Padding</div>
                    <div class="text-secondary">{{ itemData.top_padding }}</div>
                </label>
                <b-form-input
                    v-model="itemData.top_padding"
                    type="range"
                    :min="0"
                    :max="2"
                    :step="0.1"
                    @input="updateData"
                ></b-form-input>
            </div>

            <div class="flex-grow-1">
                <label class="mb-0 f-11 text-muted d-flex justify-content-between">
                    <div class="font-weight-bold">Bottom Padding</div>
                    <div class="text-secondary">{{ itemData.bottom_padding }}</div>
                </label>
                <b-form-input
                    v-model="itemData.bottom_padding"
                    type="range"
                    :min="0"
                    :max="3"
                    :step="0.1"
                    @input="updateData"
                ></b-form-input>
            </div>
        </div>

        <div>
            <label class="font-weight-bold mb-0 f-11 text-muted">Line Style</label>
            <div class="d-flex row">
                <div class="d-flex align-items-center col-md-3">
                    <b-form-radio
                        v-model="itemData.line_style"
                        name="line-style"
                        value="groove"
                        @change="updateData"
                    ></b-form-radio>
                    <div class="d-flex flex-column">
                        <div class="border-line-label groove w-100"></div>
                        <span class="f-11 text-muted text-height-1">Groove</span>
                    </div>
                </div>

                <div class="d-flex align-items-center col-md-3">
                    <b-form-radio
                        v-model="itemData.line_style"
                        name="line-style"
                        value="dashed"
                        @change="updateData"
                    ></b-form-radio>
                    <div class="d-flex flex-column">
                        <div class="border-line-label dashed w-100"></div>
                        <span class="f-11 text-muted text-height-1">Dashed</span>
                    </div>
                </div>

                <div class="d-flex align-items-center col-md-3">
                    <b-form-radio
                        v-model="itemData.line_style"
                        name="line-style"
                        value="solid"
                        @change="updateData"
                    ></b-form-radio>
                    <div class="d-flex flex-column">
                        <div class="border-line-label solid w-100"></div>
                        <span class="f-11 text-muted text-height-1">Solid</span>
                    </div>
                </div>

                <div class="d-flex align-items-center col-md-3">
                    <b-form-radio
                        v-model="itemData.line_style"
                        name="line-style"
                        value="outset"
                        @change="updateData"
                    ></b-form-radio>
                    <div class="d-flex flex-column">
                        <div class="border-line-label outset w-100"></div>
                        <span class="f-11 text-muted text-height-1">Outset</span>
                    </div>
                </div>
            </div>
            <div class="d-flex row mt-2">
                <div class="d-flex align-items-center col-md-3">
                    <b-form-radio
                        v-model="itemData.line_style"
                        name="line-style"
                        value="dotted"
                        @change="updateData"
                    ></b-form-radio>
                    <div class="d-flex flex-column">
                        <div class="border-line-label dotted w-100"></div>
                        <span class="f-11 text-muted text-height-1">Dotted</span>
                    </div>
                </div>

                <div class="d-flex align-items-center col-md-3">
                    <b-form-radio
                        v-model="itemData.line_style"
                        name="line-style"
                        value="double"
                        @change="updateData"
                    ></b-form-radio>
                    <div class="d-flex flex-column">
                        <div class="border-line-label double w-100"></div>
                        <span class="f-11 text-muted text-height-1">Double</span>
                    </div>
                </div>

                <div class="d-flex align-items-center col-md-3">
                    <b-form-radio
                        v-model="itemData.line_style"
                        name="line-style"
                        value="ridge"
                        @change="updateData"
                    ></b-form-radio>
                    <div class="d-flex flex-column">
                        <div class="border-line-label ridge w-100"></div>
                        <span class="f-11 text-muted text-height-1">Ridge</span>
                    </div>
                </div>

                <div class="d-flex align-items-center col-md-3">
                    <b-form-radio
                        v-model="itemData.line_style"
                        name="line-style"
                        value="inset"
                        @change="updateData"
                    ></b-form-radio>
                    <div class="d-flex flex-column">
                        <div class="border-line-label inset w-100"></div>
                        <span class="f-11 text-muted text-height-1">Inset</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import ColorPicker from '../ColorPicker.vue'

export default defineComponent({
    name: 'Divider',
    components: { ColorPicker },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            itemData: {
                ...this.data,
            },
        }
    },
    methods: {
        updateData() {
            this.$emit('change', this.itemData)
        },
    },
})
</script>
