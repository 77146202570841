<template>
    <div class="d-flex justify-content-between align-items-center">
        <component :is="iconComponentName" class="mr-2" />
        <span>{{ label }}</span>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { toTitleCase } from '../../helpers/stringUtils'
import IconOpenResponse from '../../stories/components/icons/IconOpenResponse.vue'
import IconMultipleChoice from '../../stories/components/icons/IconMultipleChoice.vue'
import IconFillInTheBlank from '../../stories/components/icons/IconFillInTheBlank.vue'
import IconChecklist from '../../stories/components/icons/IconChecklist.vue'
import IconMatching from '../../stories/components/icons/IconMatching.vue'
import IconWordScramble from '../../stories/components/icons/IconWordScramble.vue'
import IconHandwriting from '../../stories/components/icons/IconHandwriting.vue'
import IconWordBank from '../../stories/components/icons/IconWordBank.vue'
import IconInstruction from '../../stories/components/icons/IconInstruction.vue'
import IconSectionHeader from '../../stories/components/icons/IconSectionHeader.vue'
import IconBasicText from '../../stories/components/icons/IconBasicText.vue'
import IconDivider from '../../stories/components/icons/IconDivider.vue'
import IconBlankSpace from '../../stories/components/icons/IconBlankSpace.vue'
import IconPageBreak from '../../stories/components/icons/IconPageBreak.vue'

export default defineComponent({
    name: 'AddItemDropdownLabel',
    components: {
        IconOpenResponse,
        IconMultipleChoice,
        IconFillInTheBlank,
        IconChecklist,
        IconMatching,
        IconWordScramble,
        IconHandwriting,
        IconWordBank,
        IconInstruction,
        IconSectionHeader,
        IconBasicText,
        IconDivider,
        IconBlankSpace,
        IconPageBreak,
    },
    props: {
        iconKey: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
    },
    computed: {
        iconComponentName() {
            return `Icon${toTitleCase(this.iconKey)}`
        },
    },
})
</script>
