<!-- TODO: Find alternative -->
<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template>
    <b-dropdown ref="dropup" variant="outline-primary" no-caret size="sm" text="á" dropup>
        <div class="charmap-chars">
            <p class="text-italic text-center">Click the character you wish to copy</p>
            <input
                v-for="(symbol, index) in charmap"
                :key="'charmap-val-' + index"
                ref="charmap"
                type="text"
                :value="symbol"
            />
            <b-button
                v-for="(symbol, index) in charmap"
                :key="'charmap-' + index"
                size="sm"
                class="py-0 px-1 m-1"
                variant="outline-primary"
                @click="copyChar('charmap', index)"
                v-text="symbol"
            ></b-button>

            <input
                v-for="(symbol, index) in symbols"
                ref="symbols"
                :key="'symbol-val-' + index"
                type="text"
                :value="symbol"
            />
            <b-button
                v-for="(symbol, index) in symbols"
                :key="'symbols-' + index"
                size="sm"
                class="py-0 px-1 m-1"
                variant="outline-primary"
                @click="copyChar('symbols', index)"
                v-text="symbol"
            ></b-button>
        </div>
    </b-dropdown>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState } from 'vuex'

export default defineComponent({
    name: 'Charmap',
    data() {
        return {
            charmap: [
                'à',
                'á',
                'â',
                'ã',
                'ä',
                'å',
                'À',
                'Á',
                'Â',
                'Ã',
                'Ä',
                'Å',

                'è',
                'é',
                'ê',
                'ë',
                'È',
                'É',
                'Ê',
                'Ë',

                'ì',
                'í',
                'î',
                'ï',
                'Ì',
                'Í',
                'Î',
                'Ï',

                'ò',
                'ó',
                'ô',
                'õ',
                'ö',
                'Ò',
                'Ó',
                'Ô',
                'Õ',
                'Ö',

                'ù',
                'ú',
                'û',
                'ü',
                'Ù',
                'Ú',
                'Û',
                'Ü',

                'ý',
                'ÿ',
                'Ý',
                'Ÿ',

                'ñ',
                'Ñ',
                'æ',
                'Æ',
                'œ',
                'Œ',
                'ç',
                'Ç',
                'ð',
                'Ð',
                'ø',
                'Ø',
            ],
            symbols: ['≠', '≈', '≥', '≤', '×', '⋅', '÷', '°', 'π', '¿', '¡'],
            showCharMap: false,
        }
    },
    computed: {
        ...mapState(['worksheet']),
    },
    methods: {
        charMapAvailable() {
            if (this.$nav.active_panel === 'header' || this.$nav.active_panel === 'data') {
                return true
            } else if (this.$nav.active_panel === 'options' && this.worksheet.type === 'bingo') {
                return true
            } else if (this.$nav.active_panel === 'print' && !this.document.is_published) {
                return true
            } else if (this.$nav.active_panel === 'my_worksheets') {
                return true
            }
            return false
        },
        toggleCharMap() {
            this.showCharMap = !this.showCharMap
        },
        copyChar(map, index) {
            // TODO: See if this if/else can be removed if elem is not used.
            if (map === 'charmap') {
                let elem = this.$refs.charmap[index].select()
            } else {
                let elem = this.$refs.symbols[index].select()
            }

            document.execCommand('copy')

            this.$bvToast.toast(`Copied: ${this[map][index]}`, {
                title: `Success!`,
                variant: 'success',
                solid: true,
            })

            this.$refs.dropup.hide(true)
        },
    },
})
</script>

<style lang="scss">
@import 'Scss/base.scss';

.charmap-button {
    font-size: 16px;
    padding: 5px 10px;
    border: 0px;
    background-color: transparent;
    color: $white;

    &:hover {
        color: $purple;
        background-color: $white;

        .button-dropdown-menu {
            color: $purple;
            background-color: $white;
        }
    }
}
.charmap-chars {
    width: 400px;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 13px;
    padding: 15px;

    input {
        position: fixed;
        top: -10000px;
    }
    p {
        width: 100%;
        text-align: center;
        font-size: inherit;
        margin-top: 0px;
    }
    .button {
        margin: 0 0.2em 0.2em 0.2em;
        line-height: 1em;
        font-size: inherit;
        font-size: 1.25em;
        height: 1.5em;
        width: 1.5em;
    }
}
</style>
